
import Modal from "@/components/Modal.vue";
import FormButton from "@/components/Form/FormButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import Loader from "@/components/Loader.vue";
import {callAPI} from "@/utils/request";
import RacersTable from "@/checkout/components/RacersTable.vue";
import RacerDetail from "@/checkout/components/RacerDetail.vue";

export default {
  mounted(): void {
    this.racerName = null;
    this.foundRacers = [];
    this.filteredPriceButtons = [];
    this.selectedRacer = null;
    this.$refs.searchInput.$refs.input.focus();
    this.filteredPriceButtons = [];
  },
  data(): Record<string, any> {
    return {
      foundRacers: [],
      selectedRacer: null,
      racerName: null,
      searchTo: null,
      loading: false,
      filteredPriceButtons: [],
      priceButtonDiscount: null,
      discountReason: null,
      customPrice: null,
      basePrice: null,
    }
  },
  props: {
    race: Object,
    raceNumber: String,
    priceButtons: Array,
  },
  methods: {
    searchRacer(): void {
      clearTimeout(this.searchTo);
      if (this.racerName) {
        this.searchTo = setTimeout(() => {
          this.filteredPriceButtons = [];
          this.selectedRacer = null;
          this.loading = true;
          this.foundRacers = [];

          callAPI('search-racer', {'racer_name': this.racerName, 'race_number': this.raceNumber}, 'POST')
              .then((response: Record<string, any>) => {
                if (1 === response.data.length && this.racerName.substring(0, 3) === 'id=') {
                  this.selectedRacer = response.data[0];
                  this.filterPriceButtons()
                }
                this.foundRacers = response.data;
              }).finally(() => this.loading = false);
        }, 300)
      }
    },
    filterPriceButtons(): void {
      const filteredPriceButtons: Record<string, any> = [];
      const isChild = null !== this.selectedRacer.childrenId;

      let bonusButton = null;
      this.basePrice = 0;
      let groupPriceButtonExists = false;
      this.priceButtons.forEach((priceButton: any) => {
        if (priceButton.isBonus) {
          bonusButton = priceButton;
        } else {
          if (priceButton.isDiscount || priceButton.isFree) {
            filteredPriceButtons.push(priceButton)
          } else if (priceButton.group && this.selectedRacer.group && priceButton.group.name === this.selectedRacer.group.name && isChild === priceButton.isChild) {
            filteredPriceButtons.push(priceButton)
            groupPriceButtonExists = true;
            this.basePrice = priceButton.price;
          } else if (!priceButton.group && isChild === priceButton.isChild) {
            filteredPriceButtons.push(priceButton)
          }
          if (!groupPriceButtonExists && priceButton.isBasePrice && isChild === priceButton.isChild) {
            this.basePrice = priceButton.price;
          }
        }
      })

      /* remove all non-group price buttons */
      if (groupPriceButtonExists) {
        filteredPriceButtons.forEach((priceButton: any, index: number) => {
          if (!priceButton.group && !priceButton.isDiscount && !priceButton.isFree) {
            filteredPriceButtons.splice(index, 1);
          }
        });
      }

      // add bonus button when conditions are met
      if (bonusButton && this.basePrice && this.basePrice <= this.selectedRacer.totalBonus) {
        filteredPriceButtons.unshift(bonusButton)
      }

      this.filteredPriceButtons = filteredPriceButtons;
    },
    priceButtonClicked(button: Record<string, any>): void {
      if (button.isDiscount) {
        this.priceButtonDiscount = button;
      } else {
        this.priceButtonDiscount = null;
        this.createRaceVehicle(button);
      }
    },
    createRaceVehicle(button: Record<string, any>): void {
      const racerId = this.selectedRacer.id;

      this.racerName = null;
      this.foundRacers = [];
      this.filteredPriceButtons = [];
      this.selectedRacer = null;

      callAPI('create-race-vehicle', {
        'button_id': button.id,
        'racer_id': racerId,
        'race_number': parseInt(this.raceNumber),
        'custom_price': parseFloat(this.customPrice),
        'discount_reason': this.discountReason,
        'base_price': this.basePrice
      }, 'POST')
          .then(() => {
            this.customPrice = null;
            this.discountReason = null;
            this.$emit('raceVehicleCreated');
            this.$refs.searchInput.$refs.input.focus();
          });
    }
  },
  components: {
    FormButton,
    Modal,
    FormInput,
    Loader,
    RacersTable,
    RacerDetail,
  },
  emits: ['raceVehicleCreated'],
};
