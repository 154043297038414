
import FormButton from "@/components/Form/FormButton.vue";
import InlineSvg from "vue-inline-svg";
import {callAPI} from "@/utils/request";
import Loader from "@/components/Loader.vue";
import ReprintTicketModal from "@/checkout/components/ReprintTicketModal.vue";
import ManageBonusModal from "@/checkout/components/ManageBonusModal.vue";

export default {
  props: {
    selectedRace: Object
  },
  data(): Record<string, any> {
    return {
      maxRacersCount: 8,
      races: null,
      activeRaceNumber: null,
      selectedRaceNumber: null,
      showManageBonusModal: false,
      showReprintTicketModal: false,
    }
  },
  watch: {
    selectedRace: function (newValue: Record<string, any>): void {
      if (newValue) {
        this.selectedRaceNumber = newValue.race.number
      } else {
        this.selectedRaceNumber = null;
      }
    }
  },
  mounted(): void {
    this.loadRaces();
  },
  components: {
    Loader,
    FormButton,
    ReprintTicketModal,
    InlineSvg,
    ManageBonusModal
  },
  methods: {
    loadRaces(): void {
      callAPI('race-list')
          .then((response: Record<string, any>) => {
            this.races = response.data.races;
            this.activeRaceNumber = response.data.activeRaceNumber;
            this.$emit('racesLoaded', response.data.trackId);
          })
          .catch((exception: any) => {
            if (typeof exception.response === 'undefined') {
              this.$router.push({name: 'login_cs'})
            }
          });
    },
  },
  emits: ['raceClicked', 'racesLoaded']
};
