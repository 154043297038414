
import InlineSvg from "vue-inline-svg";
import {accessObjectByDotNotation} from "@/utils/app";
import Badge from "@/components/Badge.vue";

export default {
  data(): Record<string, any> {
    return {}
  },
  props: {
    racer: Object
  },
  computed: {
    racerPhotoPath(): string {
      return this.computeRacerPhotoPath(this.racer);
    },
  },
  methods: {
    computeRacerPhotoPath(racer: Record<string, any>): string {
      return racer.photoPath ? process.env.VUE_APP_BASE_URL_KARTCOMMANDER + '/' + racer.photoPath : require('@/assets/img/kartcommander.png');
    },
    getRacerPropertyValue(property: string): string {
      let value = accessObjectByDotNotation(this.racer, property);
      if (value === null) {
        return ' - ';
      }

      if (property === 'genderAsText') {
        return this.$t(value);
      } else if (property === 'birthDate') {
        return this.$d(new Date(value), 'date');
      }

      return value;
    },
  },
  components: {
    InlineSvg,
    Badge,
  },
};
