import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd6798b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "races-col col" }
const _hoisted_2 = { class: "positions-col col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Races = _resolveComponent("Races")!
  const _component_Positions = _resolveComponent("Positions")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Races, {
        onRaceClicked: _cache[0] || (_cache[0] = ($event: any) => ($options.showRaceDetail($event))),
        onRacesLoaded: _cache[1] || (_cache[1] = ($event: any) => ($options.connectToSocket($event))),
        "selected-race": $data.selectedRace,
        ref: "races"
      }, null, 8, ["selected-race"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Positions, {
        onPositionsFinished: _cache[2] || (_cache[2] = ($event: any) => ($data.selectedRace = null)),
        onReloadRacesAndPositions: _cache[3] || (_cache[3] = ($event: any) => {$options.showRaceDetail($event); this.$refs.races.loadRaces()}),
        onReloadRaces: _cache[4] || (_cache[4] = ($event: any) => (this.$refs.races.loadRaces())),
        "selected-race": $data.selectedRace,
        "active-race-number": $data.activeRaceNumber,
        "show-loader": $data.positionsLoading,
        "price-buttons": $data.priceButtons
      }, null, 8, ["selected-race", "active-race-number", "show-loader", "price-buttons"])
    ])
  ], 64))
}