
import FlashMessages from "@/components/FlashMessages/FlashMessages.vue";
import {createAbortController} from "@/utils/request";

export default {
  mounted(): void {
    createAbortController();
  },
  components: {
    FlashMessages,
  },
};
