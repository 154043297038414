import {createRouter, createWebHistory, Router, RouteRecordRaw} from "vue-router";
import router, {appRoutes} from "@/registration/router";
import i18n, {availableLocales} from "@/registration/i18n";

export function setupRouter(appRoutes: Record<string, any>, defaultTitle: string): Router {
    const routes: Array<RouteRecordRaw> = [];
    let routeName: string, routeSettings: any;
    for ([routeName, routeSettings] of Object.entries(appRoutes)) {
        let locale: string, localeSettings: any;
        for ([locale, localeSettings] of Object.entries(routeSettings.locales)) {
            const meta: any = {};
            if (typeof routeSettings.middleware !== 'undefined') {
                meta.middleware = routeSettings.middleware
            }

            routes.push(
                {
                    path: localeSettings.path,
                    name: routeName + '_' + locale,
                    component: routeSettings.component,
                    meta
                }
            );
        }
    }

    routes.push({
        path: '/:pathMatch(.*)*',
        name: 'not_found',
        component: () => import('@/components/PageNotFound.vue'),
    });

    const router = createRouter({
        history: createWebHistory(process.env.BASE_URL),
        routes
    })

    setMiddleware(router);
    adjustPageTitleAndMetaTags(router, defaultTitle);

    return router;
}

export function changeRouteByLocale(langIso: string): void {
    if (router.currentRoute.value.name) {
        const routeGroupNameParts = router.currentRoute.value.name.toString().split('_');
        routeGroupNameParts.pop();
        const routeGroupName = routeGroupNameParts.join('_');

        if (typeof appRoutes[routeGroupName] !== 'undefined') {
            let targetRoute = appRoutes[routeGroupName].locales[langIso].path;

            const params = router.currentRoute.value.params;
            for (const paramName in params) {
                targetRoute = targetRoute.replace(':' + paramName, params[paramName]);
            }

            router.push(targetRoute);
        }
    }
}

export function getPath(routeName: string): string {
    return appRoutes[routeName].locales[i18n.global.locale.value].path;
}

export function redirectToRoute(routeName: string): void {
    router.push(getPath(routeName));
}

export function setLocaleByRoute(): void {
    for (const routeData of Object.entries(appRoutes)) {
        const routeSettings = routeData[1];
        let locale: string, localeSettings: any;
        for ([locale, localeSettings] of Object.entries(routeSettings.locales)) {
            if (localeSettings.path === router.currentRoute.value.path) { // full match
                i18n.global.locale.value = locale;
                return;
            } else if (localeSettings.path.includes('/:')) {   // remove placeholders and find match
                const definedRouteParts = localeSettings.path.split('/');
                const currentRouteParts = router.currentRoute.value.path.split('/');
                definedRouteParts.forEach((routePart: string, key: number) => {
                    if (routePart.includes(':')) {  //exclude placeholders from path
                        delete definedRouteParts[key];
                        delete currentRouteParts[key];
                    }
                });

                if (definedRouteParts.join('/') === currentRouteParts.join('/')) {
                    i18n.global.locale.value = locale;
                    return;
                }
            }
        }
    }
}

export function setMiddleware(router: Router): void {
    router.beforeEach((to, from, next) => {
        if (!to.meta.middleware) {
            return next()
        }
        const middleware: any = to.meta.middleware

        return middleware(next)
    });
}

export function adjustPageTitleAndMetaTags(router: Router, defaultTitle: string): void {
    router.beforeEach((to, from, next) => {
        const locale = to.name?.toString().split('_').pop();

        if (locale && locale.toString() in Object.keys(availableLocales)) {
            i18n.global.locale.value = locale;
        }

        // Remove any stale meta tags from the document using the key attribute we set below.
        Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => {
            if (el.parentNode) {
                el.parentNode.removeChild(el)
            }
        });

        const metaAttributes = {
            'description': {
                name: 'description',
                content: '%translation%'
            },
            'ogDescription': {
                property: 'og:description',
                content: '%translation%'
            }
        };

        let routeName = to.matched[0].name?.toString();
        const routeNameParts = routeName ? routeName.split('_') : [];
        if (typeof routeNameParts !== 'undefined') {
            if (routeName !== 'not_found') {
                routeNameParts.pop(); //remove locale
                routeName = routeNameParts.join('_');
            }

            for (const [metaAttributeKey, metaAttributeData] of Object.entries(metaAttributes)) {
                const translationKey = 'route.' + routeName + '.' + metaAttributeKey;
                const translation = i18n.global.t(translationKey);
                if (translationKey !== translation) { // translation exists
                    const metaTag = document.createElement('meta');
                    for (const [metaAttributeName, metaAttributeValue] of Object.entries(metaAttributeData)) {
                        metaTag.setAttribute(metaAttributeName, metaAttributeValue.replace('%translation%', translation));
                    }

                    metaTag.setAttribute('data-vue-router-controlled', '');
                    document.head.appendChild(metaTag)
                }
            }

            const titleTranslationKey = 'route.' + routeName + '.title';
            const titleTranslation = i18n.global.t(titleTranslationKey);
            const defaultTitleTranslated = i18n.global.t(defaultTitle);
            if (titleTranslationKey !== titleTranslation) {
                document.title = titleTranslation + ' | ' + defaultTitleTranslated;
            } else {
                document.title = defaultTitleTranslated;
            }
        }

        return next();
    });
}
