
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'Badge',
  props: {
    text: {
      require: true,
      type: String
    },
  },
});
