
import FormButton from "@/components/Form/FormButton.vue";
import FormInput from "@/components/Form/FormInput.vue";
import InlineSvg from "vue-inline-svg";
import Loader from "@/components/Loader.vue";
import {RouterLink} from "vue-router";
import {callAPI} from "@/utils/request";
import RacerModal from "@/checkout/components/RacerModal.vue";

export default {
  props: {
    selectedRace: Object,
    showLoader: Boolean,
    priceButtons: Array,
    activeRaceNumber: Number,
  },
  data(): Record<string, any> {
    return {
      raceLength: null,
      racers: [],
      blockRaceLoading: false,
      showRacerModal: false,
    }
  },
  watch: {
    selectedRace: function (newValue: Record<string, any>): void {
      if (newValue && newValue.race.length !== null) {
        this.raceLength = newValue.race.length
        this.racers = newValue.racers
      }
    }
  },
  components: {
    FormButton,
    FormInput,
    InlineSvg,
    Loader,
    RouterLink,
    RacerModal,
  },
  methods: {
    save(): void {
      callAPI('check-not-printed-tickets-exists', {'race_id': this.selectedRace.race.id}, 'POST')
          .then((response) => {
            if (response.data) {
              let routeData = this.$router.resolve({name: 'print_ticket_cs', params: {raceId: this.selectedRace.race.id}});
              this.$emit('positionsFinished');
              window.open(routeData.href, '_blank');
            } else {
              this.$emit('positionsFinished');
            }
          });
    },
    logout(): void {
      callAPI('logout')
          .then(() => {
            localStorage.removeItem('user');
            this.$router.push({name: 'login_cs'})
          });
    },
    removeRacer(index: number): void {
      callAPI('remove-race-vehicle', {'race_vehicle_id': this.selectedRace.racers[index].raceVehicleId}, 'POST')
          .then(() => {
            this.racers.splice(index, 1);
            this.$emit('reloadRaces');
          });
    },
    changeRaceLength(): void {
      callAPI('change-race-length', {'race_number': parseInt(this.selectedRace.race.number), 'race_length': this.raceLength}, 'POST')
          .then(() => {
            this.$emit('reloadRaces');
          });
    },
    blockRace(): void {
      this.blockRaceLoading = true;
      callAPI('block-race', {'race_number': parseInt(this.selectedRace.race.number)}, 'POST')
          .then(() => {
            this.$emit('reloadRacesAndPositions', this.selectedRace.race.number);
          })
          .finally(() => this.blockRaceLoading = false);
    },
    unblockRace(): void {
      this.blockRaceLoading = true;
      callAPI('unblock-race', {'race_id': parseInt(this.selectedRace.race.id)}, 'POST')
          .then(() => {
            this.$emit('reloadRacesAndPositions', this.selectedRace.race.number);
          })
          .finally(() => this.blockRaceLoading = false);
    },
    skipRace(): void {
      callAPI('skip-race', {'race_id': parseInt(this.selectedRace.race.id)}, 'POST')
          .then(() => {
            this.$emit('reloadRacesAndPositions', this.selectedRace.race.number);
          });
    },
    unskipRace(): void {
      callAPI('unskip-race', {'race_id': parseInt(this.selectedRace.race.id)}, 'POST')
          .then(() => {
            this.$emit('reloadRacesAndPositions', this.selectedRace.race.number);
          });
    }
  },
  computed: {
    raceNumber(): string {
      return this.selectedRace.race.number <= 9 ? '0' + this.selectedRace.race.number : this.selectedRace.race.number
    }
  },
  emits: ['positionsFinished', 'reloadRacesAndPositions', 'reloadRaces']
};
