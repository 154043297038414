import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17aa1a2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "racer-suggestions" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "row ms-0 me-0 mt-3" }
const _hoisted_5 = {
  key: 0,
  class: "col ps-0 pe-0 d-flex align-items-end"
}
const _hoisted_6 = { class: "me-3" }
const _hoisted_7 = { class: "me-3" }
const _hoisted_8 = {
  key: 3,
  class: "text-center pt-3"
}
const _hoisted_9 = { class: "price-buttons" }
const _hoisted_10 = { class: "price-buttons__button__title" }
const _hoisted_11 = {
  key: 0,
  class: "price-buttons__button__price"
}
const _hoisted_12 = {
  key: 1,
  class: "price-buttons__button__price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormInput = _resolveComponent("FormInput")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_RacersTable = _resolveComponent("RacersTable")!
  const _component_RacerDetail = _resolveComponent("RacerDetail")!
  const _component_FormButton = _resolveComponent("FormButton")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "racerModal",
    title: 'Jízda ' + $props.raceNumber,
    id: "racer-modal",
    "modal-class-name": "modal--full-height"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormInput, {
        onKeyup: $options.searchRacer,
        label: "Jméno jezdce",
        name: "racerName",
        modelValue: $data.racerName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.racerName) = $event)),
        type: "search",
        ref: "searchInput"
      }, null, 8, ["onKeyup", "modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        ($data.loading)
          ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
          : ($data.foundRacers.length && !$data.selectedRacer)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_RacersTable, {
                  racers: $data.foundRacers,
                  onRacerSelected: _cache[1] || (_cache[1] = ($event: any) => {$data.selectedRacer = $event; $options.filterPriceButtons()})
                }, null, 8, ["racers"])
              ]))
            : ($data.selectedRacer)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_RacerDetail, { racer: $data.selectedRacer }, null, 8, ["racer"]),
                  _createElementVNode("div", _hoisted_4, [
                    ($data.priceButtonDiscount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_FormInput, {
                              label: "Důvod",
                              name: "discount_reason",
                              modelValue: $data.discountReason,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.discountReason) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            (!$data.priceButtonDiscount.isFree)
                              ? (_openBlock(), _createBlock(_component_FormInput, {
                                  key: 0,
                                  type: "number",
                                  min: "0",
                                  label: "Vlastní cena",
                                  name: "discount_amount",
                                  modelValue: $data.customPrice,
                                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.customPrice) = $event))
                                }, null, 8, ["modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_FormButton, {
                            type: "submit",
                            "class-name": "btn btn-primary",
                            label: "OK",
                            disabled: $data.priceButtonDiscount.isFree ? !$data.discountReason : (!$data.discountReason || !$data.customPrice),
                            onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.createRaceVehicle($data.priceButtonDiscount)))
                          }, null, 8, ["disabled"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, " Žádná data "))
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.filteredPriceButtons, (button) => {
          return (_openBlock(), _createBlock(_component_FormButton, {
            onClick: ($event: any) => ($options.priceButtonClicked(button)),
            "class-name": "btn btn-lg price-buttons__button",
            disabled: !$data.selectedRacer,
            style: _normalizeStyle({backgroundColor: button.color}),
            key: button.id
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_10, _toDisplayString(button.title), 1),
              (button.price)
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(button.price) + " Kč", 1))
                : _createCommentVNode("", true),
              (button.isBonus)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString($data.basePrice) + " bodů", 1))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["onClick", "disabled", "style"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}