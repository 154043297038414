import {createApp} from 'vue'
import App from './App.vue'
import checkoutRouter from './router/';
import './assets/scss/design.scss'
import routerPlugin from '../plugins/router'
import {emitter} from '@/utils/app';
import i18n from "@/registration/i18n";

const app = createApp(App).use(checkoutRouter).use(i18n).use(routerPlugin);
app.config.globalProperties.emitter = emitter;
app.mount('#app');