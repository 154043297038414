
import Modal from "@/components/Modal.vue";
import Loader from "@/components/Loader.vue";
import Datatable from "@/components/Datatable.vue";
import {callAPI} from "@/utils/request";
import {h} from "vue";
import InlineSvg from "vue-inline-svg";

export default {
  mounted(): void {
    this.tickets = null;
    callAPI('reprint-ticket')
        .then((response: Record<string, any>) => {
          this.tickets = response.data;
        }).finally(() => this.loading = false);
  },
  data(): Record<string, any> {
    return {
      columns: [
        {label: 'Číslo jízdy', name: 'raceNumber'},
        {label: 'Pozice', name: 'startingPosition'},
        {label: 'Jméno', name: 'racer'},
        {label: 'Dítě', name: 'isChild', callback: this.childColumn},
        {label: 'Tisk', name: 'raceRaceVehicleId', callback: this.printColumn}
      ],
      tickets: null,
    }
  },
  props: {},
  methods: {
    childColumn(data: boolean): any {
      if (data) {
        return h('span', {
          'class': 'racer-is-child',
        }, [
          h(InlineSvg, {
            'src': require('@/assets/img/icons/baby.svg'),
            'width': '20',
            'fill': '#ffffff'
          }),
        ]);
      }

      return null;
    },
    printColumn(raceRaceVehicleId: string): any {
      const ids = raceRaceVehicleId.split('_');

      let routeData = this.$router.resolve({name: 'print_ticket_cs', params: {raceId: ids[0], raceVehicleId: ids[1]}});
      return h('a', {
        'href': routeData.href,
        'target': '_blank',
        'class': 'btn btn-secondary w-100',
      }, 'Tisk');
    },
  },
  computed: {},
  components: {
    Modal,
    Loader,
    Datatable,
  },
};
