import Home from '../views/Home.vue'
import {setupRouter} from "@/utils/router";

export const appRoutes: Record<string, any> = {
    home: {
        component: Home,
        locales: {
            cs: {
                path: '/',
            },
        }
    },
    login: {
        component: () => import('../views/Login.vue'),
        locales: {
            cs: {
                path: '/prihlaseni',
            },
        }
    },
    settings: {
        component: () => import('../views/Settings.vue'),
        locales: {
            cs: {
                path: '/nastaveni',
            },
        }
    },
    print_ticket: {
        component: () => import('../views/PrintTicket.vue'),
        locales: {
            cs: {
                path: '/tisk/vstupenka/:raceId/:raceVehicleId?',
            },
        }
    },
}

export default setupRouter(appRoutes, 'Pokladna')
