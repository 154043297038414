
import InlineSvg from "vue-inline-svg";

export default {
  data(): Record<string, any> {
    return {}
  },
  props: {
    racers: Array
  },
  methods: {
    computeRacerPhotoPath(racer: Record<string, any>): string {
      return racer.photoPath ? process.env.VUE_APP_BASE_URL_KARTCOMMANDER + '/' + racer.photoPath : require('@/assets/img/kartcommander.png');
    },
  },
  components: {
    InlineSvg,
  },
  emits: ['racerSelected'],
};
