
import {defineComponent, h} from 'vue';
import {accessObjectByDotNotation} from '@/utils/app';
import FormInput from "@/components/Form/FormInput.vue";
import FormSelect from "@/components/Form/FormSelect.vue";
import parse from 'date-fns/parse'
import {datetimeFormatsUnicode} from "@/registration/i18n";

export default defineComponent({
  name: 'Datatable',
  props: {
    columns: {
      require: true,
      type: Object,
    },
    data: {
      require: true,
      type: Object,
    },
    columnsPrefix: {
      type: String
    },
    perPage: {
      type: Number,
      default: 10
    },
    search: {
      type: Boolean,
      default: true
    },
    rowIndex: {
      type: String,
      default: 'id'
    },
  },
  data() {
    return {
      perPageSelected: this.perPage,
      currentPage: 1,
      searchPattern: '',
      sortBy: [],
      formattedData: [],
    }
  },
  mounted() {
    this.formatColumnValues();
  },
  methods: {
    formatColumnValues() {
      this.formattedData = JSON.parse(JSON.stringify(this.data));
      const dataLength = typeof this.formattedData === 'object' ? Object.keys(this.formattedData).length : this.formattedData.length;
      const columnsLength = this.columns.length;
      for (let rowIdx = 0; rowIdx < dataLength; rowIdx++) {
        for (let colIdx = 0; colIdx < columnsLength; colIdx++) {
          let columnOptions = this.columns[colIdx];
          let columnName = this.getColumnName(columnOptions);
          let value = accessObjectByDotNotation(this.data[rowIdx], columnName);

          if (typeof columnOptions === 'object') {
            if (columnOptions.type === 'date') {
              value = this.$d(new Date(value), columnOptions.format);
            }
            if (columnOptions.translate === true) {
              value = this.$t(value)
            }
          }

          this.formattedData[rowIdx][columnName] = value;
        }
      }
    },
    getColumnLabel(column: any) {
      if (typeof column === 'object') {
        if (typeof column.label !== 'undefined') {
          return column.label;
        }

        return column.name;
      }

      return column;
    },
    getColumnName(column: any) {
      if (typeof column === 'object') {
        return column.name;
      }

      return column;
    },
    sortColumn(column: any, columnIndex: number): void {
      let dir;

      if (typeof this.sortBy[0] !== 'undefined' && this.sortBy[0].columnIndex !== columnIndex) {
        this.sortBy[0].dir = null;
      }

      if (typeof this.sortBy[0] === 'undefined' || !this.sortBy[0].dir) {
        dir = 'asc';
      } else if (this.sortBy[0].dir === 'asc') {
        dir = 'desc';
      } else {
        dir = null;
      }

      this.sortBy[0] = {
        dir: dir,
        columnName: this.getColumnName(column),
        columnIndex,
      }
    },
    getColumnValue(row: any, column: any) {
      let value = row[this.getColumnName(column)];

      // needs to be defined here in order to keep reactivity
      if (typeof column === 'object' && typeof column.callback !== 'undefined') {
        return column.callback(value, row);
      }

      return h('span', value);
    },
    getScalarValue(initialValue: any) {
      if (initialValue && typeof initialValue === 'object') {
        let scalarValue = '';
        initialValue.forEach((item: any) => {
          if (item.children) {
            scalarValue += item.children;
          }
        })

        return scalarValue;
      }

      return initialValue;
    },
  },
  computed: {
    filteredData(): object[] {
      const searchPattern = this.searchPattern.toLowerCase();
      let filteredData: any = [];

      const dataLength = typeof this.formattedData === 'object' ? Object.keys(this.formattedData).length : this.formattedData.length;
      const columnsLength = this.columns.length;
      for (let rowIdx = 0; rowIdx < dataLength; rowIdx++) {
        let row = this.formattedData[rowIdx];
        if (searchPattern) {
          let showRow = false;

          for (let colIdx = 0; colIdx < columnsLength; colIdx++) {
            let value = this.getColumnValue(row, this.columns[colIdx]);
            if (value.children && typeof value.children !== 'object' && value.children.toLowerCase().includes(searchPattern)) {
              showRow = true
              break;
            }
          }

          if (showRow) {
            filteredData.push(row);
          }
        } else {
          filteredData.push(row);
        }
      }

      if (typeof this.sortBy[0] !== 'undefined' && this.sortBy[0].dir) {
        let dir = this.sortBy[0].dir;
        filteredData = filteredData.sort((a: any, b: any) => {
          let column = this.columns[this.sortBy[0].columnIndex];
          let aValue = this.getColumnValue(a, column).children;
          let bValue = this.getColumnValue(b, column).children;

          if (typeof column === 'object' && typeof column.type !== 'undefined' && column.type === 'date') {
            let dateFormat = datetimeFormatsUnicode[this.$i18n.locale][column.format];
            aValue = parse(aValue, dateFormat, new Date());
            bValue = parse(bValue, dateFormat, new Date());
          } else {
            aValue = this.getScalarValue(aValue);
            bValue = this.getScalarValue(bValue);
          }

          if (!isNaN(aValue)) {
            aValue = parseFloat(aValue);
          }

          if (!isNaN(bValue)) {
            bValue = parseFloat(bValue);
          }

          if (aValue === bValue) {
            return 0;
          } else {
            let sortValue = aValue < bValue ? -1 : 1;
            return dir === 'asc' ? sortValue : sortValue * -1;
          }
        });
      }

      return filteredData;
    },
    pagingPageNumbers(): object {
      let pagingPageNumbers = [];
      pagingPageNumbers.push(1);

      for (let pageNumber = 2; pageNumber < this.pageTotal; pageNumber++) {
        let diffToPage = this.currentPage - pageNumber;
        if (Math.abs(diffToPage) < 2 || (pageNumber < 4 && this.currentPage < 4)) {
          pagingPageNumbers.push(pageNumber);
        }
        if (Math.abs(diffToPage) == 2) {
          pagingPageNumbers.push('...');
        }
      }

      pagingPageNumbers.push(this.pageTotal);

      return pagingPageNumbers;
    },
    pageStart(): number {
      return (this.currentPage - 1) * this.perPageSelected;
    },
    pageEnd(): number {
      let pageEnd = this.pageStart + parseInt(this.perPageSelected);
      if (pageEnd > this.filteredData.length) {
        pageEnd = this.filteredData.length;
      }
      return pageEnd;
    },
    pageData(): object[] {
      return this.filteredData.slice(this.pageStart, this.pageEnd);
    },
    pageTotal(): number {
      return Math.ceil(this.filteredData.length / this.perPageSelected);
    },
    perPageOptions(): number[] {
      let perPageOptions: number[] = [];
      [10, 25, 100, 250, 500].forEach((perPage) => {
        if (perPage <= this.formattedData.length) {
          perPageOptions.push(perPage);
        }
      });

      perPageOptions.push(parseInt(this.perPageSelected)); // in case of custom option

      return [...new Set(perPageOptions.sort(function (a, b) {
        return a - b
      }))];  // return unique and sorted values
    },
  },
  watch: {
    'data'(): void {
      this.currentPage = 1;
      this.searchPattern = '';
      this.formatColumnValues();
    }
  },
  components: {
    FormInput,
    FormSelect
  }
});
