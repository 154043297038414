
import Races from "@/checkout/components/Races.vue";
import Positions from "@/checkout/components/Positions.vue";
import {callAPI} from "@/utils/request";

export default {
  mounted(): void {
    callAPI('price-buttons')
        .then((response: Record<string, any>) => {
          this.priceButtons = response.data;
        });
  },
  data(): Record<string, any> {
    return {
      selectedRace: null,
      positionsLoading: false,
      priceButtons: [],
      positionsLoadingTimeOut: null,
      activeRaceNumber: null,
      socketInitialized: false
    }
  },
  components: {
    Races,
    Positions
  },
  methods: {
    showRaceDetail(event: any): void {
      let raceNumber = event;
      if (typeof event === 'object') {
        this.activeRaceNumber = event.activeRaceNumber;
        raceNumber = event.raceNumber
      }

      /* show loader after 500 ms */
      this.positionsLoadingTimeOut = setTimeout(() => {
        this.positionsLoading = true
      }, 500);

      callAPI('race-detail', {'race_number': raceNumber}, 'GET')
          .then((response: Record<string, any>) => {
            clearTimeout(this.positionsLoadingTimeOut);
            this.selectedRace = response.data;
            this.positionsLoading = false
          });
    },
    connectToSocket(trackId: number) {
      if (!this.socketInitialized) {

        let socket = new WebSocket(process.env.VUE_APP_WSS + 'checkout');
        socket.onopen = () => {
          this.socketInitialized = true;
          socket.send('{"track_id":' + trackId + '}');  //register for track updates
        };

        socket.onclose = () => {
          setTimeout(() => {
            this.connectToSocket(trackId);
          }, 1000);
        };

        socket.onmessage = (event) => {
          let data = JSON.parse(event.data);
          if (data.update) {
            this.$refs.races.loadRaces()
          }
        };

        // socket.onerror = () => {
        //   console.log('Socket error');
        // };
      }
    }
  },
};
