
import Modal from "@/components/Modal.vue";
import {callAPI} from "@/utils/request";
import FormInput from "@/components/Form/FormInput.vue";
import RacersTable from "@/checkout/components/RacersTable.vue";
import Loader from "@/components/Loader.vue";
import FormButton from "@/components/Form/FormButton.vue";
import RacerDetail from "@/checkout/components/RacerDetail.vue";

export default {
  data(): Record<string, any> {
    return {
      foundRacers: [],
      selectedRacer: null,
      racerName: null,
      searchTo: null,
      loading: false,
      bonusToSubstract: null,
      bonusReason: null,
      substractBonusRunning: false,
      validationMessages: [],
    }
  },
  props: {},
  methods: {
    searchRacer(): void {
      clearTimeout(this.searchTo);

      this.selectedRacer = null;
      this.foundRacers = [];
      this.bonusToSubstract = null;
      this.bonusReason = null;
      this.validationMessages = [];

      if (this.racerName) {
        this.searchTo = setTimeout(() => {
          this.loading = true;

          callAPI('search-racer', {'racer_name': this.racerName, 'race_number': this.raceNumber}, 'POST')
              .then((response: Record<string, any>) => {
                this.foundRacers = response.data;
              })
              .finally(() => this.loading = false);
        }, 300)
      }
    },
    substractBonus(): void {
      this.substractBonusRunning = true;
      this.validationMessages = [];

      callAPI('substract-bonus', {'racer_id': this.selectedRacer.id, 'bonus_to_substract': this.bonusToSubstract, 'bonus_reason': this.bonusReason}, 'POST')
          .then(() => {
            this.racerName = null;
            this.foundRacers = [];
            this.selectedRacer = null;
            this.emitter.emit('add-flash', {'success': 'Body úspěšně odečteny'})
          })
          .catch((exception: any) => {
            if (422 === exception.response.status) {
              this.validationMessages = exception.data.error;
            }
          })
          .finally(() => this.substractBonusRunning = false);
    }
  },
  computed: {},
  components: {
    Modal,
    RacersTable,
    FormInput,
    Loader,
    FormButton,
    RacerDetail,
  },
};
