import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    ref: "modal",
    title: "Dotisk vstupenky",
    "modal-class-name": "modal--full-height"
  }, {
    default: _withCtx(() => [
      (null !== $data.tickets)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_Datatable, {
              columns: $data.columns,
              data: $data.tickets,
              "row-index": "raceRaceVehicleId"
            }, null, 8, ["columns", "data"])
          ]))
        : (_openBlock(), _createBlock(_component_Loader, { key: 1 }))
    ]),
    _: 1
  }, 512))
}